const data = [
    {
        "id": "疫情",
        "label": "疫情",
        "value": 8828,
        "type": "keyword"
    },
    {
        "id": "簡單",
        "label": "簡單",
        "value": 1202,
        "type": "keyword"
    },
    {
        "id": "民進黨",
        "label": "民進黨",
        "value": 1161,
        "type": "group"
    },
    {
        "id": "垃圾",
        "label": "垃圾",
        "value": 1112,
        "type": "keyword"
    },
    {
        "id": "美國",
        "label": "美國",
        "value": 1112,
        "type": "location"
    },
    {
        "id": "防疫",
        "label": "防疫",
        "value": 1062,
        "type": "keyword"
    },
    {
        "id": "日本",
        "label": "日本",
        "value": 1016,
        "type": "location"
    },
    {
        "id": "武漢肺炎",
        "label": "武漢肺炎",
        "value": 956,
        "type": "keyword"
    },
    {
        "id": "高雄",
        "label": "高雄",
        "value": 905,
        "type": "location"
    },
    {
        "id": "國民黨",
        "label": "國民黨",
        "value": 903,
        "type": "group"
    },
    {
        "id": "學生",
        "label": "學生",
        "value": 811,
        "type": "keyword"
    },
    {
        "id": "五樓",
        "label": "五樓",
        "value": 754,
        "type": "keyword"
    },
    {
        "id": "一個人",
        "label": "一個人",
        "value": 724,
        "type": "keyword"
    },
    {
        "id": "崩潰",
        "label": "崩潰",
        "value": 717,
        "type": "keyword"
    },
    {
        "id": "紓困",
        "label": "紓困",
        "value": 690,
        "type": "keyword"
    },
    {
        "id": "蟑螂",
        "label": "蟑螂",
        "value": 689,
        "type": "keyword"
    },
    {
        "id": "韓國瑜",
        "label": "韓國瑜",
        "value": 680,
        "type": "people"
    },
    {
        "id": "申請",
        "label": "申請",
        "value": 663,
        "type": "keyword"
    },
    {
        "id": "同學",
        "label": "同學",
        "value": 651,
        "type": "keyword"
    },
    {
        "id": "不意外",
        "label": "不意外",
        "value": 627,
        "type": "keyword"
    },
    {
        "id": "在一起",
        "label": "在一起",
        "value": 620,
        "type": "keyword"
    },
    {
        "id": "病毒",
        "label": "病毒",
        "value": 617,
        "type": "keyword"
    },
    {
        "id": "邏輯",
        "label": "邏輯",
        "value": 612,
        "type": "keyword"
    },
    {
        "id": "支那",
        "label": "支那",
        "value": 610,
        "type": "keyword"
    },
    {
        "id": "智障",
        "label": "智障",
        "value": 594,
        "type": "keyword"
    },
    {
        "id": "蔡英文",
        "label": "蔡英文",
        "value": 593,
        "type": "people"
    },
    {
        "id": "開心",
        "label": "開心",
        "value": 589,
        "type": "keyword"
    },
    {
        "id": "蘋果",
        "label": "蘋果",
        "value": 581,
        "type": "brand"
    },
    {
        "id": "台中",
        "label": "台中",
        "value": 558,
        "type": "location"
    },
    {
        "id": "男生",
        "label": "男生",
        "value": 541,
        "type": "keyword"
    },
    {
        "id": "可憐哪",
        "label": "可憐哪",
        "value": 534,
        "type": "keyword"
    },
    {
        "id": "肥宅",
        "label": "肥宅",
        "value": 532,
        "type": "keyword"
    },
    {
        "id": "短網址",
        "label": "短網址",
        "value": 524,
        "type": "keyword"
    },
    {
        "id": "畢業",
        "label": "畢業",
        "value": 522,
        "type": "keyword"
    },
    {
        "id": "真相",
        "label": "真相",
        "value": 519,
        "type": "keyword"
    },
    {
        "id": "台灣價值",
        "label": "台灣價值",
        "value": 513,
        "type": "keyword"
    },
    {
        "id": "直播",
        "label": "直播",
        "value": 493,
        "type": "keyword"
    },
    {
        "id": "柯文哲",
        "label": "柯文哲",
        "value": 484,
        "type": "people"
    },
    {
        "id": "攻擊",
        "label": "攻擊",
        "value": 465,
        "type": "keyword"
    },
    {
        "id": "李來希",
        "label": "李來希",
        "value": 451,
        "type": "people"
    },
    {
        "id": "劇情",
        "label": "劇情",
        "value": 449,
        "type": "keyword"
    },
    {
        "id": "口罩",
        "label": "口罩",
        "value": 449,
        "type": "keyword"
    },
    {
        "id": "KMT",
        "label": "KMT",
        "value": 448,
        "type": "group"
    },
    {
        "id": "臉書",
        "label": "臉書",
        "value": 439,
        "type": "brand"
    },
    {
        "id": "醫生",
        "label": "醫生",
        "value": 438,
        "type": "keyword"
    },
    {
        "id": "戴口罩",
        "label": "戴口罩",
        "value": 237,
        "type": "keyword"
    },
    {
        "id": "開車",
        "label": "開車",
        "value": 136,
        "type": "keyword"
    },
    {
        "id": "投票",
        "label": "投票",
        "value": 125,
        "type": "keyword"
    }
];
export default data
    .map(d => {
    return {
        ...d,
        label: ''
    };
});
