export default {
    colors: ['#ffffff', '#dddddd', '#bbbbbb', '#999999', '#777777'],
    types: ['keyword', 'people', 'location', 'group', 'brand'],
    // collisionSpacing: 2,
    groupMode: 'center',
    padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
    }
};
