import { defineComponent, ref, onMounted } from 'vue';
import TheHeader from '@/components/TheHeader.vue';
import TheSearchBar from '@/components/TheSearchBar.vue';
import TheFooter from '@/components/TheFooter.vue';
import TheDemoContent from '@/components/TheDemoContent/TheDemoContent.vue';
export default defineComponent({
    components: {
        TheHeader,
        TheSearchBar,
        TheFooter,
        TheDemoContent
    },
    setup() {
        const isShowButton = ref(false);
        setTimeout(() => {
            isShowButton.value = true;
        }, 1100);
        return {
            isShowButton
        };
    }
});
