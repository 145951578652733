import { defineComponent, ref, onMounted } from 'vue';
import Parallax from "vue-parallaxy";
import CodeHighlight from "vue-code-highlight/src/CodeHighlight.vue";
import "vue-code-highlight/themes/duotone-sea.css";
import "vue-code-highlight/themes/window.css";
import TheHeader from '@/components/TheHeader.vue';
import TheSearchBar from '@/components/TheSearchBar.vue';
import TheFooter from '@/components/TheFooter.vue';
import { D3ModuleContainer } from '@bpchart/vue';
import { ChartBubbleGroup } from '@bpchart/d3-modules/chartBubbleGroup';
import animate1Data from './animate1Data';
import animate1Params from './animate1Params';
export default defineComponent({
    components: {
        Parallax,
        CodeHighlight,
        TheHeader,
        TheSearchBar,
        TheFooter,
        D3ModuleContainer
    },
    setup() {
        const currentAnimate1Params = ref(animate1Params);
        const isShowCodeHighlight = ref(false);
        // const currentAnimate1Data = ref(animate1Data)
        onMounted(() => {
            let i = 0;
            setInterval(() => {
                let groupMode = 'center';
                if (i % 3 === 0) {
                    groupMode = 'type';
                }
                else if (i % 3 === 1) {
                    groupMode = 'rank';
                }
                else if (i % 3 === 2) {
                    groupMode = 'center';
                }
                currentAnimate1Params.value = {
                    ...currentAnimate1Params.value,
                    groupMode
                };
                // currentAnimate1Data.value.push({
                //   "id": `test${Math.abs(Math.random()*1000)}`,
                //   "label": "",
                //   "value": 1202,
                //   "type": "keyword"
                // })
                i++;
            }, 5000);
            setTimeout(() => {
                isShowCodeHighlight.value = true;
            }, 1000);
        });
        return {
            animate1Data,
            currentAnimate1Params,
            ChartBubbleGroup,
            isShowCodeHighlight
        };
    }
});
